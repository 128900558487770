@use "../../sassStyles/variables" as v;
@use "../../sassStyles/mixins" as m;


.footer {
  display: flex;
  justify-content: center;
  padding: 20px 10px;

  p {
    color: v.$primary;
    font-size: 16px;
  }

  a {
    transition: 0.3s ease-in-out;
    color: v.$primary;
    font-size: 16px;

    &:hover {
      color: v.$highlight1;
    }
  }
}
