@use "../../../sassStyles/_variables" as v;
@use "../../../sassStyles/_mixins" as m;

.smbar__container {
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  margin-bottom: 40px;
  position: relative;

  @include m.smMinBreakPoint {
    display: none;
  }
}

.smbar__title {
  font-size: 16px;
  font-weight: bold;
  color: v.$primary;
  border-right: v.$primary solid 2px;
  padding-right: 40px;
  z-index: 2;
  box-shadow: 6px 0 10px v.$primaryDark3;
}

.smbar__icons {
  display: grid;
  align-items: center;
  justify-content: space-between;
  padding-left: 40px;
  position: relative;
  grid-template-columns: repeat(7, max-content);
  grid-column-gap: 35px;
  overflow: scroll;

  .smbar__spacer {
    width: 15px;
    height: 20px;
  }

}

.smbar__fade-right {
  position: absolute;
  right: -1px;
  top: 0;
  width: 30px;
  height: 100%;
  z-index: 3;
  background: linear-gradient(270deg, #39251C 0%, rgba(57, 37, 28, 0) 100%);

}


