@use "../../../sassStyles/variables" as v;
@use "../../../sassStyles/mixins" as m;

.about-hero__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 70px;
}

.about-hero__icon {
  width: 110px;
  height: 74px;
  z-index: 1;

  @include m.smMinBreakPoint {
    width: 145px;
    height: 97.63px;
  }
}

.about-hero__title {
  text-align: center;
  color: v.$secondary1;
  margin-bottom: 10px;
  z-index: 1;
  max-width: 360px;

  @include m.smMinBreakPoint {
    max-width: 470px;
  }

  @include m.mdMinBreakPoint {
    max-width: 570px;
  }

  @include m.lgMinBreakPoint {
    max-width: 780px;
    font-size: 74px;
  }
}

.about-hero__img {
  width: 100%;
  height: 205px;
  background: url("../../../assets/GEN_About_01.jpg") center;
  background-size: cover;
  z-index: -1;
  bottom: 0;

  @include m.smMinBreakPoint {
    height: 495px;
  }
}
