@use "../../../sassStyles/_variables" as v;
@use "../../../sassStyles/_mixins" as m;

.social-link {
  display: grid;
  grid-template-columns: min-content min-content;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  img {
    transition: 0.3s ease-in-out;
  }

  p {
    margin: 0;
    padding-left: 10px;
    display: inline-block;
    color: v.$highlight1;
    transition: color 0.3s ease-in-out;
  }

  &:hover p {
    color: v.$primary;
  }
}

//Icons Disabled
.social-link--disabled {
  pointer-events: none;
  cursor: default;

  .social-svg--disabled{
    fill: v.$primary;
  }

}



//Sidebar Icons
.social-link--sidebar img {

  width: 22px;

}


//Mobile Hero Icons
.social-link--mobile-hero img {
  height: 25px;
}


//Mobile Nav Icons
.social-link--mobile-nav img {
  height: 30px;
}


//Contact Icons
.social-link--contact {
  margin-bottom: 25px;

  @include m.lgMinBreakPoint {
    justify-content: start;
  }

  &:hover {
    transform: scale(1);
  }

  img {
    width: 22px;
    height: auto;
    transition: 0.3s ease-in-out;
  }

}
