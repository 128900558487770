@use "./_variables" as v;

// Breakpoints
@mixin smMinBreakPoint {
  @media (min-width: #{v.$screen-sm}) {
    @content;
  }
}

@mixin mdMinBreakPoint {
  @media (min-width: #{v.$screen-md}) {
    @content;
  }
}

@mixin lgMinBreakPoint {
  @media (min-width: #{v.$screen-lg}) {
    @content;
  }
}

@mixin xlMinBreakPoint {
  @media (min-width: #{v.$screen-xl}) {
    @content;
  }
}

@mixin xxlMinBreakPoint {
  @media (min-width: #{v.$screen-xxl}) {
    @content;
  }
}

@mixin customMinBreakPoint($size) {
  @media (min-width: $size+'px') {
    @content;
  }
}

@mixin customMaxBreakPoint($size) {
  @media (max-width: $size+'px') {
    @content;
  }
}

//Fonts
@mixin poppinsFontStack {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
}

@mixin playfairFontStack {
  font-family: 'Playfair Display', Iowan Old Style, Apple Garamond, Baskerville, Times New Roman, Droid Serif, Times, Source Serif Pro, serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}
