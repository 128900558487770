@use "../../sassStyles/variables" as v;
@use "../../sassStyles/mixins" as m;


.mbt__layout {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: block;
  margin: 0 auto;
  z-index: 50;

  @include m.smMinBreakPoint {
    display: none;
  }
}

