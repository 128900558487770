@use "../../../sassStyles/_variables" as v;
@use "../../../sassStyles/_mixins" as m;

.mc__title {
  max-width: 235px;
  text-align: center;
  color: v.$secondary1;
  margin: 25px auto 35px;

  @include m.smMinBreakPoint {
    margin: 0 auto 30px;
  }
}

.mc__form-container {
  width: 100%;
  margin: 0 auto;
  transition: height .2s ease;
}

.mc__form {
  display: grid;
  grid-template-rows: min-content min-content;
  grid-row-gap: 40px;
  justify-content: stretch;
  align-items: center;
  transition: height .2s ease;
}

.mc__field-container {
  display: grid;
  grid-row-gap: 30px;
}

.mc__alert {
  text-align: center;
  @include m.poppinsFontStack;

  &--sending {
    color: v.$highlight2;
    -webkit-animation: fade-loop 0.8s ease-in-out infinite alternate-reverse both;
    animation: fade-loop 0.8s ease-in-out infinite alternate-reverse both;
  }

  &--success {
    color: v.$secondary2;
    margin-top: -60px;
  }

  &--error {
    color: v.$warning;
    font-weight: bold;


    a {
      color: v.$highlight1;
      transition: .3s ease-in-out;
      font-weight: normal;
      display: block;
      margin-top: 5px;
      opacity: .7;

      &:hover {
        text-decoration: none;
        opacity: 1;
      }
    }
  }
}

