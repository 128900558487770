@use "./_variables" as v;
* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: v.$primaryDark3;
}

input[type=text], input[type=button] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

.g__justify-self-center {
  justify-self: center;
}

