@use "../../../sassStyles/variables" as v;
@use "../../../sassStyles/mixins" as m;


.about__container {
  padding-bottom: 40px;
  @include m.smMinBreakPoint {
    padding-right: 15px;
  }
}

.about__section-title {
  color: v.$secondary1;
  text-align: center;
  margin: 60px 0;

  @include m.smMinBreakPoint {
    margin: 100px 0 40px;
    text-align: left;
  }
}


.about__content-section {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 20px;

  @include m.lgMinBreakPoint {
    grid-template-columns: 50% 1fr;
  }

}

.about__content-subtitle {
  color: v.$secondary2;
  font-weight: normal;

  margin-top: 0;
}

.about__content-text {
  color: v.$secondary1;
}

.about__image-container {
  position: relative;

  &::after {
    content: ' ';
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.about__content-image {
  width: 100%;
  height: auto;
}

.about__quote-container {
  color: v.$primary;
  max-width: 925px;
  margin: 120px auto;
}

.about__quote {
  @include m.playfairFontStack();
  font-size: 23px;
  line-height: 33px;
  text-align: center;
  margin: 0;

  @include m.lgMinBreakPoint {
    font-size: 36px;
    line-height: 48px;
  }
}

.about__quote-author {
  text-align: center;
  font-size: 16px;

  @include m.lgMinBreakPoint {
    font-size: 18px;
  }
}

.about__milestone-container {
  margin: 120px 0;
}

.about__milestone-cards {
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 50px;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  margin: 50px auto 0;

  @include m.lgMinBreakPoint {
    justify-items: normal;
  }
}

.about__partners {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 20px;
  margin-top: 30px;

  @include m.smMinBreakPoint {
    grid-template-columns: 1fr 1fr;
  }
}

.about__partner-img {
  height: 100px;
  width: auto;
}

.about__partner-text {
  color: v.$secondary1;
  font-style: italic;
  font-weight: normal;
}
