@use "../../../sassStyles/variables" as v;
@use "../../../sassStyles/mixins" as m;
.videoCard__container {
  border: 1px solid #2b170d;
  max-width: 305px;
  height: max-content;
  display: block;
  box-shadow: 0px -16px 14px rgba(33, 17, 10, .4);
  text-decoration: none;
  transition: .3s ease-in-out;

  &:hover {
    box-shadow: 0px -5px 14px rgba(33, 17, 10, .1);
    transform: translateY(-5px);
  }

  &:hover .videoCard__play svg {
    fill: v.$highlight1;
  }

  &:hover .videoCard__play p {
    color: v.$highlight1;
  }
}

.videoCard__image-container {
  overflow: hidden;
  height: 168px;
  display: flex;
  align-items: center;
  position: relative;

  &::after {
    content: ' ';
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: -1px;
    z-index: 1;
    background: linear-gradient(0deg, #39251C 0%, rgba(57, 37, 28, 0) 100%);
  }
}


.videoCard__image {
  width: 100%;
  height: auto;
}

.videoCard__meta-container {
  padding: 0 28px 16px;
}


.videoCard__title {
  margin: -15px 0 0 0;
  position: relative;
  z-index: 2;
  color: v.$highlight1;

}

.videoCard__meta-info {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 20px;
  margin-top: 12px;
}

.videoCard__play {

  display: flex;
  align-items: center;

  svg {
    fill: v.$primary;
    margin-right: 5px;
    transition: .3s ease-in-out;
  }

  p {
    margin: 0;
    text-transform: uppercase;
    color: v.$primary;
    font-weight: bold;
    font-size: 15px;
    transform: translateY(-1px);
    transition: .3s ease-in-out;
  }
}

.videoCard__date {
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }

  p {
    margin: 0;
    text-transform: uppercase;
    color: v.$primary;
    font-weight: bold;
    font-size: 15px;
    transform: translateY(-1px);
  }
}
