@use "../../../sassStyles/_variables" as v;
@use "../../../sassStyles/_mixins" as m;

.crisis__container {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include m.mdMinBreakPoint {
    position: relative;
    padding: 100px 0;
    justify-content: normal;
  }
}

.crisis__image-container {
  @include m.mdMinBreakPoint {
    width: 100%;
  }
}

.crisis__content-column {
  max-width: 415px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include m.mdMinBreakPoint {
    position: absolute;
    right: 15px;
    align-items: flex-start;
  }
}

.crisis__headline {
  font-size: 74px;
  color: v.$secondary1;
  margin: 0 0 10px;
}

.crisis__tab-container {
  padding: 0;
}
